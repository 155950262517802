




















import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class ShowsListItemSkeleton extends Vue {}
