






import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockColorMixin, blockableBlockColorFragment } from '@/composables/block-color';

import { PageBlockMixin } from '@/modules/cms';
import { ScenkonstCalendarBlockDataFragment } from '@/graphql/generated';

@Component({
  graphqlData: gql`
    fragment ScenkonstCalendarBlockData on ScenkonstCalendarBlock {
      id
      title
      ...BlockColor
    }

    ${blockableBlockColorFragment}
  `,
})
export default class ScenkonstCalendarBlock extends mixins(PageBlockMixin<ScenkonstCalendarBlockDataFragment>(), blockColorMixin) {}
