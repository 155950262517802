















import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class SkeletonRect extends Vue {
  @Prop()
  value!: string;

  @Prop(String)
  placeholder!: string;

  @Prop(Boolean)
  block!: boolean;

  @Prop(Number)
  ratio!: number;

  get valueLoading () {
    return !this.value;
  }

  get ariaLabel () {
    return this.valueLoading ? 'Laddar innehåll' : null;
  }

  get paddingTop () {
    if (!this.ratio)
      return 0;

    return `${(1 / this.ratio) * 100}%`;
  }
}
